import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 50px;
  background: #0f0f0f;
  width: 100%;
  padding: 40px 20px;
  height: 860px;

  flex-wrap: wrap;

  span {
    color: #fff;
    font-size: 22px;
    margin-bottom: 150px;
    margin-right: 50px;
    margin-left: 50px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 300px;
  }
`;

export const EngineerImage = styled.img`
  height: 430px;
  max-width: 100%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
`;
