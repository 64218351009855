import React from "react";

import { Container, EngineerImage, Right } from "./styles";
import engineerImage from "../../assets/engineer-image.svg";

interface MainProps {
  id: string;
}

const Main = ({ id }: MainProps) => {
  return (
    <Container id={id}>
      <span>Serviços Elétricos</span>
      <EngineerImage src={engineerImage} alt="foto_engenheiro" />

      <Right>
        <span>Assessoria</span>
        <span>Consultoria</span>
      </Right>
    </Container>
  );
};

export default Main;
