import React from "react";

import { Container } from "./styles";

const About = ({ id }: any) => {
  return (
    <Container id={id}>
      <p>
        A ACEL é uma empresa de consultoria e assessoria em eletricidade,
        fundada em março de 2001 que presta serviços no ramo da qualidade e
        eficiência energética. Seu corpo técnico é formado por profissionais
        oriundos de empresa distribuidora de energia, com vasta experiência,
        adquirida em muitos anos de trabalho em conceituada distribuidora de
        energia do estado de São Paulo e dirigida por um engenheiro eletricista,
        pós-graduado e especialista em sistemas de MT, que conhece a
        regulamentação e a legislação tarifária, o qual lhe permite estar
        analisando e repassando aos seus clientes as melhores condições para o
        uso eficiente e racional da energia elétrica.
      </p>
      <br />
      <p>
        A ACEL avalia as contas de energia das empresas, verificando tarifas,
        demandas e consumos indevidos. Levanta os gastos desnecessários
        incidentes nestas contas e orienta o cliente em como reduzir estes
        gastos. Faz análise do custo-benefício das melhorias que podem ser
        implantadas e repassa para seus clientes. Na maioria das vezes, o
        investimento se autoviabiliza e o cliente não tem despesas extras.
        Personalizamos as soluções para os clientes, buscando sempre os menores
        custos.
      </p>
      <br />
      <p>
        A ACEL busca sempre a solução de menor custo para o cliente, seja ela
        padronizada ou personalizada, conforme o interesse do cliente e do
        perfil de carga da instalação, ou seja: para cada cliente uma solução
        diferente, com os menores custos possíveis. A ACEL dimensiona, fabrica,
        instala e faz o acompanhamento posterior de todos os equipamentos
        necessários para fazer a compensação reativa da instalação elétrica. A
        economia gerada nos meses seguintes paga o equipamento redutor, e a
        redução na conta fica para sempre.
      </p>
    </Container>
  );
};

export default About;
