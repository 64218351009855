import styled from "styled-components";

export const Container = styled.div`
  background-color: #0f0f0f;
  color: #fff;
  display: flex;
  height: 860px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  flex-wrap: wrap;
  padding-bottom: 50px;

  @media screen and (max-width: 600px) {
    height: 1500px;
  }

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    height: 1500px;
  }
`;

export const SectionText = styled.p`
  font-size: 20px;
  margin-top: 30px;

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    font-size: 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 280px;
  margin-left: 50px;
  margin-top: 40px;
`;

export const SectionImg = styled.img`
  height: 50px;
  width: 45px;

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    height: 100px;
    width: 90px;
  }
`;

export const WrapperTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
