import React from 'react';
import Routes from '../src/Routes';

import './App.css'

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
