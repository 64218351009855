import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 900px;
  z-index: 1;
  padding: 100px;
  font-size: 1.5rem;
  font-weight: bold;

  @media screen and (max-width: 600px) {
    height: 1200px;
    margin-bottom: 300px;
  }

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    height: 1600px;
    font-size: 2rem;
  }

  p {
    @media screen and (max-device-width: 480px) and (orientation: portrait) {
      font-size: 30px;
    }
  }
`;
