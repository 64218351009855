import React from "react";

import { Container, SectionText, SectionImg, Wrapper } from "./styles";
import building from "../../assets/building.svg";
import bolt from "../../assets/bolt.svg";
import check from "../../assets/check.svg";
import battery from "../../assets/battery.svg";
import lightbulb from "../../assets/lightbulb.svg";
import statistics from "../../assets/statistics.svg";

const Services = ({ id }: any) => {
  return (
    <Container id={id}>
      <Wrapper>
        <SectionImg src={building} />
        <SectionText>
          Reformas elétricas em prédios comerciais, galpões industriais,
          hospitais e escritórios
        </SectionText>
      </Wrapper>
      <Wrapper>
        <SectionImg src={bolt} />
        <SectionText>
          Instalação de iluminação de emergência e sistema de para-raios(SPDA)
        </SectionText>
      </Wrapper>
      <Wrapper>
        <SectionImg src={check} />
        <SectionText>
          Inspeção, vistoria e avaliação de instalaçoes elétricas para emissão
          de laudos elétricos
        </SectionText>
      </Wrapper>
      <Wrapper>
        <SectionImg src={battery} />
        <SectionText>
          Instalação e substituição de transformadores e geradores
        </SectionText>
      </Wrapper>
      <Wrapper>
        <SectionImg src={lightbulb} />
        <SectionText>
          Elaboração de projetos e montagem de instalações elétricas (quadros
          elétricos, eletrodutos, circuitos de iluminação e tomadas, cabeamento
          estruturado
        </SectionText>
      </Wrapper>
      <Wrapper>
        <SectionImg src={statistics} />
        <SectionText>Análise de consumo e custos de energia</SectionText>
      </Wrapper>
    </Container>
  );
};

export default Services;
